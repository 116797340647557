import axios from "axios";
import store from "store";

const API_URL = process.env.REACT_APP_API_URL;

export interface Viewer {
  email: string;
  loggedIn: boolean;
  name: string;
  role: string;
}

export async function getViewer(googleIdToken?: string): Promise<Viewer> {
  let token = googleIdToken;
  if (!token) {
    token = store.get("token");
  }
  if (!token || token === "") {
    store.remove("token");
    throw new Error("Invalid authentication. Please login again.");
  }
  const response = await axios.post(`${API_URL}/viewer`, {
    token
  });
  if (response.status !== 200) {
    store.remove("token");
    throw new Error("Invalid authentication.");
  }
  store.set("token", token);
  return response.data;
}

export async function submitNameForCalling(name: string, calling: string) {
  const token = store.get("token");
  if (!token || token === "") {
    store.remove("token");
    throw new Error("Invalid authentication. Please login again.");
  }
  const response = await axios.post(
    `${API_URL}/submit-name-for-calling`,
    {
      name,
      calling
    },
    { headers: { Authorization: token } }
  );

  return response.data;
}
