import React from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login";
import { Flex, Box, Text, Button, Alert, useToast } from "@chakra-ui/core";
import { getViewer } from "../../services/api";
import { useHistory } from "react-router-dom";

interface Props {}

export function Login(props: Props) {
  const history = useHistory();
  const toast = useToast();

  async function onLoginSuccess(
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) {
    console.log("Success!");
    console.dir(response);
    const offlineRes = response as GoogleLoginResponseOffline;
    if (offlineRes.code) {
      console.log("offline");
      return null;
    }

    const res = response as GoogleLoginResponse;

    try {
      const viewerResponse = await getViewer(res.tokenId);
      toast({
        title: "Login Successful",
        description: `Welcome ${viewerResponse.name}!`,
        duration: 3000,
        isClosable: true,
        status: "success"
      });
      history.push("/dashboard");
    } catch (e) {
      let message = e.message;
      if (e.response && e.response.data) {
        message = e.response.data;
      }
      toast({
        title: "Login Error",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  }

  function onLoginFailure() {
    toast({
      title: "Login Error",
      description:
        "There was something wrong with your login. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true
    });
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Box maxWidth={500} p={4}>
        <Text fontSize="4xl" mb={4}>
          Woodhaven Ward
        </Text>
        <Alert status="info" mb={2}>
          Get started by using the button below to login using Google. Only
          authorized users will be allowed access to this app.
        </Alert>
        <GoogleLogin
          clientId="362163321386-vg1ga4rkrvkq40mtsr5meqcaj1a61lkb.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={onLoginSuccess}
          onFailure={onLoginFailure}
          cookiePolicy={"single_host_origin"}
          render={renderProps => (
            <Button
              onClick={renderProps.onClick}
              isDisabled={renderProps.disabled}
              variantColor="blue"
            >
              Login with Google
            </Button>
          )}
        />
      </Box>
    </Flex>
  );
}
