import React from "react";
import { Box, Flex, Button, Text } from "@chakra-ui/core";
import { useViewer } from "../../hooks/use-viewer";
import { useHistory, Link } from "react-router-dom";

interface Props {}

export function Dashboard(props: Props) {
  const { viewer, isLoading } = useViewer();
  const history = useHistory();

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!viewer) {
    history.push("/");
    return <Text>Not logged in.</Text>;
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Box maxWidth={300} p={4}>
        <Text fontSize="4xl" mb={4} textAlign="center">
          Woodhaven Ward
        </Text>
        <Text fontSize="md" mb={2} textAlign="center">
          {viewer.name}
        </Text>
        <Box mb={2}>
          <Link to="/submit-name-for-calling">
            <Button isFullWidth variantColor="blue">
              Submit Name for Calling
            </Button>
          </Link>
        </Box>
        <Box>
          <a
            href="https://drive.google.com/drive/folders/1ka0f6CEr-2yM3r8Sn7RMomR_BRPRDAID?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button isFullWidth variantColor="blue">
              Ward Council Google Drive
            </Button>
          </a>
        </Box>
      </Box>
    </Flex>
  );
}
