import { useState, useEffect } from "react";
import { Viewer, getViewer } from "../services/api";

export function useViewer() {
  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  async function loadViewer() {
    setIsLoading(true);
    try {
      console.log("useViewer making /viewer request");
      const viewerFromApi = await getViewer();
      setViewer(viewerFromApi);
      setError(null);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      if (!viewer && !error) {
        await loadViewer();
      }
    })();
  }, [viewer, error]);

  return {
    viewer,
    isLoading
  };
}
