import React from "react";
import { CSSReset, ThemeProvider } from "@chakra-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
  // Redirect
} from "react-router-dom";
import { LoginRoute } from "./routes/login";
import { DashboardRoute } from "./routes/dashboard";
import { SubmitNameForCallingRoute } from "./routes/submit-name-for-calling";
import { DefaultRoute } from "./routes/default";

interface Props {}

export function App(props: Props) {
  return (
    <ThemeProvider>
      <CSSReset />
      <Router>
        <Switch>
          <Route path="/" exact>
            <DefaultRoute />
          </Route>
          <Route path="/dashboard" exact>
            <DashboardRoute />
          </Route>
          <Route path="/submit-name-for-calling" exact>
            <SubmitNameForCallingRoute />
          </Route>
          <Route path="/login" exact>
            <LoginRoute />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
