import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useToast,
  Link,
  FormErrorMessage
} from "@chakra-ui/core";
import { useViewer } from "../../hooks/use-viewer";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { submitNameForCalling } from "../../services/api";
import { Formik, Form, yupToFormErrors, FormikHelpers } from "formik";
import * as yup from "yup";

interface Props {}

interface FormValues {
  calling: string;
  name: string;
}

const SubmitNameForCallingSchema = yup.object().shape({
  calling: yup
    .string()
    .label("Calling")
    .required()
    .min(3)
    .max(255)
    .nullable(false),
  name: yup
    .string()
    .label("Name to Submit")
    .required()
    .min(3)
    .max(255)
    .nullable(false)
});

export function SubmitNameForCalling(props: Props) {
  const { viewer, isLoading } = useViewer();
  const [calling, setCalling] = useState("");
  const [name, setName] = useState("");
  const toast = useToast();

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!viewer) {
    return <Redirect to="/" />;
  }

  async function onSubmit(
    values: FormValues,
    formikHelpers: FormikHelpers<{
      calling: string;
      name: string;
    }>
  ) {
    const { calling, name } = values;

    try {
      await submitNameForCalling(name, calling);
      toast({
        title: "Name was submitted.",
        description: `${name} was submitted for ${calling}.`,
        status: "success"
      });
      formikHelpers.resetForm();
    } catch (e) {
      toast({
        title: "Something went wrong",
        description: e.message,
        status: "error"
      });
    }
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Formik
        initialValues={{ calling: "", name: "" }}
        onSubmit={onSubmit}
        validationSchema={SubmitNameForCallingSchema}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched
        }) => (
          <Form>
            <Box maxWidth={600} p={4}>
              <RouterLink to="/">
                <Link>&lt; Back to Dashboard</Link>
              </RouterLink>
              <Text fontSize="4xl" mb={4}>
                Submit a Name for a Calling
              </Text>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="submittedBy">Submitted By</FormLabel>
                  <Input
                    type="text"
                    id="submittedBy"
                    value={viewer.name}
                    isDisabled
                  />
                </FormControl>
                <FormControl
                  isInvalid={!!touched.calling && !!errors.calling}
                  isRequired
                >
                  <FormLabel htmlFor="calling">Calling</FormLabel>
                  <Input
                    type="text"
                    id="calling"
                    name="calling"
                    autoFocus
                    value={values.calling}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.calling}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!touched.name && !!errors.name}
                  isRequired
                >
                  <FormLabel htmlFor="name">Name to Submit</FormLabel>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <Button
                  mt={4}
                  variantColor="blue"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </Form>
        )}
      />
    </Flex>
  );
}
