import React from "react";
import { useViewer } from "../../hooks/use-viewer";
import { Text } from "@chakra-ui/core";
import { Redirect } from "react-router-dom";

export function DefaultRoute() {
  const { viewer, isLoading } = useViewer();
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  if (!viewer) {
    return <Redirect to="/login" />;
  }
  return <Redirect to="/dashboard" />;
}
